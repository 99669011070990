import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'gatsby'
import LayoutJSX from '../layouts/layout-jsx'

const NotFoundPage = () => (
  <LayoutJSX
    title="Page non trouvée" >
    <Container>
      <span>Veuillez vérifier l&rsquo;adresse spécifiée, ou <Link to="/">retournez à la page d&rsquo;accueil</Link>.</span>
    </Container>
  </LayoutJSX>
)

export default NotFoundPage
